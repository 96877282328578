<template>
  <div id="rounds-bg" style="min-height: 100vh">
    <div class="round-loading" v-if="loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <div id="rounds-content" v-else>
      <div class="round-accesses-header">
        <a class="round-accesses-header-back-page" @click="$router.go(-1)">
          <font-awesome-icon
            icon="fa-solid fa-arrow-left"
            class="round-accesses-header-back-page-icon"
          />
          <span class="round-accesses-header-back-page-text">{{
            `
            ${$t("single_round")} #${roundId} 
          `
          }}</span>
        </a>
        <div class="round-accesses-header-flex">
          <div>
            <h1 class="round-accesses-header-title">
              <span>{{ `${$t("single_round")} #${roundId}` }}</span>
              <font-awesome-icon
                icon="fa-solid fa-chevron-right"
                class="icon-arrow-breadcrumb"
              />
              <span>{{ $t("round_accesses_breadcrumb") }}</span>
            </h1>
            <p class="round-accesses-header-sub-title">
              {{ $t("round_accesses_subtitle") }}
            </p>
          </div>
        </div>
      </div>
      <div>
        <RoundAccessesTableComponente :roundId="roundId" />
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import RoundAccessesTableComponente from "./components/RoundAccessesTableComponente.vue";

export default {
  name: "RoundAccesses",
  components: {
    RoundAccessesTableComponente,
  },
  data() {
    return {
      roundId: null,
      loading: false,
      roundData: null,
      apiService: new ApiService(),
    };
  },
  async created() {
    const roundId = this.$route.params.roundId;

    if (roundId) this.roundId = roundId;
  },
  methods: {},
};
</script>

<style scoped>
#rounds-bg {
  position: absolute;
  top: -75px;
  width: 100%;
  min-height: 100vh !important;
  background-color: var(--white);
}

#rounds-content {
  /* 75px precisa ser fixo para das a distancia do AppBar */
  margin-top: calc(75px + 20px);
  width: 100%;
  padding: 0 60px;
  background-color: var(--white);
  padding-bottom: 40px;
}
.round-accesses-content {
  padding: 0 60px;
}
.round-accesses-header {
  margin-bottom: 40px;
}

.round-accesses-header-back-page {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.round-accesses-header-back-page-icon {
  width: 22px;
  height: 22px;
  color: var(--primary);
}

.round-accesses-header-back-page-text {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

.round-accesses-header-title {
  font-family: "Source Sans Pro";
  font-weight: 700;
  font-size: 35px;
  line-height: 44px;
  letter-spacing: 0.15px;
  color: var(--dark);
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.round-accesses-header-sub-title {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  letter-spacing: 0.15px;
  color: #535353;
}

.round-accesses-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.round-accesses-table {
  margin-top: 40px;
}

.icon-arrow-breadcrumb {
  width: 24px;
  height: 24px;
  color: #b3b3b3;
  margin-left: 16px;
  margin-right: 16px;
}

.round-loading {
  display: grid;
  place-items: center;
  margin-top: 120px;
}
</style>
