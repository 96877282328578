<template>
  <div class="interest-table-component-container">
    <div>
      <v-form
        @submit.stop.prevent="getAccessesByPage"
        class="interest-table-component-filter-container"
      >
        <div>
          <span class="interests-table-component-filters-label">{{
            $t("search_investors")
          }}</span>
          <v-text-field
            v-model="searchInput"
            color="primary"
            dense
            class="interests-table-component-filters-auto-complete"
            outlined
          >
          </v-text-field>
        </div>
        <div class="interest-table-component-button-filter">
          <NewDxaButton
            type="submit"
            :outline="true"
            :title="$tc('filter_kpi', 1)"
            @btnFunction="getAccessByPage"
            :loading="loading"
          />
        </div>
      </v-form>
    </div>
    <div class="box-loading" v-if="loading">
      <v-progress-circular
        indeterminate
        size="70"
        color="primary"
      ></v-progress-circular>
    </div>
    <v-simple-table v-else>
      <thead class="interest-table-thead">
        <th class="text-left">
          <div class="table-thead-flex">
            {{ $t("investor_name") }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.INVESTOR
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.INVESTOR)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.INVESTOR
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
        <th class="text-left">
          <div class="table-thead-flex">{{ $t("access_granted") }}</div>
        </th>
        <th class="text-left">
          <div class="table-thead-flex">
            {{ $t("viewed_last") }}
            <font-awesome-icon
              class="arrow-table-icon"
              :color="`${
                changeIconOrder === orderFilterEnum.VIEWED_LAST
                  ? 'var(--primary)'
                  : '#AFAFAF'
              }`"
              @click="headerClick(orderFilterEnum.VIEWED_LAST)"
              :icon="`fa-solid ${
                changeIconOrder === orderFilterEnum.VIEWED_LAST
                  ? 'fa-chevron-up'
                  : 'fa-chevron-down'
              }`"
            />
          </div>
        </th>
      </thead>
      <tbody class="interest-table-body">
        <tr v-for="(access, i) in accesses" :key="i">
          <td @click="goInvestorProfile(access.investorId)">
            <span id="interest-table-body-investor-name">
              {{ access.investorFullName }}
            </span>
          </td>
          <td>
            <span>{{ formatDate(access.accessGrantedDate) }}</span>
          </td>
          <td>
            <span>{{ formatDate(access.lastAccessDate) }}</span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <v-pagination
      v-if="!loading"
      class="mt-6"
      color="primary"
      @input="changePage"
      v-model="page"
      :length="pageCount"
    >
    </v-pagination>
  </div>
</template>

<script>
import NewDxaButton from "@/components/dxa/Button/NewDxaButton";
import { roundAccessesTableEnum } from "../RoundStatusEnums";
import ApiService from "@/services/ApiService";
import { formatDate } from "@/shared/helpers/dateHelper";

const OrderFilterEnum = {
  INVESTOR: roundAccessesTableEnum.INVESTOR,
  VIEWED_LAST: roundAccessesTableEnum.VIEWED_LAST,
};

const OrderByModeEnum = {
  ASC: 1,
  DESC: 2,
};

export default {
  name: "RoundAccessesTableComponente",
  components: {
    NewDxaButton,
  },
  props: {
    roundId: Number,
  },
  data() {
    return {
      searchInput: null,
      accesses: [],
      loading: false,
      page: 1,
      pageCount: 1,
      orderBy: null,
      orderByMode: OrderByModeEnum.ASC,
      orderFilterEnum: OrderFilterEnum,
      orderByModeEnum: OrderByModeEnum,
      formatDate,
      apiService: new ApiService(),
    };
  },
  async created() {
    await this.getAccessesByPage();
  },
  methods: {
    changePage() {
      this.getAccessesByPage();
    },
    goInvestorProfile(investorId) {
      this.$router.push(`/users/edit/${investorId}`);
    },
    headerClick(col) {
      this.orderBy = col;

      if (col === this.changeIconOrder) {
        this.changeIconOrder = null;
      } else {
        this.changeIconOrder = col;
      }

      if (this.orderByMode == 1) {
        this.orderByMode = 2;
      } else {
        this.orderByMode = 1;
      }
      this.getAccessesByPage();
    },
    async getAccessesByPage() {
      this.loading = true;
      let currentPage = this.page;

      let params = { page: currentPage };

      if (this.roundId) {
        params.investmentRoundId = Number(this.roundId);
      }

      if (this.searchInput) {
        params.filterName = this.searchInput;
      }

      if (this.orderBy != null) {
        params.orderBy = this.orderBy;
        params.order = this.orderByMode;
      }

      await this.apiService
        .postRequest(`investmentround/accesses`, params)
        .then((response) => {
          const totalItemsPorPage = response.content.perPage;

          this.page = response.content.currentPage;
          this.pageCount = Math.ceil(
            response.content.total / totalItemsPorPage
          );
          this.accesses = response.content.items;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$toast.error(this.$t("error_occoured"));
        });
    },
  },
};
</script>

<style scoped>
.interest-table-component-container {
  background: var(--white);
  padding: 20px;
  border-radius: 8px;
}

.interests-table-component-filters-label {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #535353;
  margin-bottom: 8px;
  display: block;
}

.interests-table-component-filters-auto-complete {
  min-width: 210px;
}

.interest-table-component-button-filter {
  max-width: 100px;
}

.interest-table-component-filter-container {
  display: flex;
  gap: 20px;
  align-items: center;
}

.allocation-date-flex-items {
  display: flex;
  align-items: center;
  gap: 20px;
}

.allocation-date-item-space-letter {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.15px;
  color: #535353;
  display: block;
  margin-bottom: 20px;
}

/* etilos da tabela */
.interest-table-thead {
  height: 80px;
}

.interest-table-thead > th {
  font-family: "Source Sans Pro";
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
  padding-left: 16px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.interest-table-body > td {
  padding-left: 0px !important;
}

.interest-table-body td > span {
  font-family: "Source Sans Pro";
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.15px;
  color: var(--dark);
}

#interest-table-body-investor-name {
  font-weight: 700;
  text-decoration-line: underline;
  color: var(--primary);
  cursor: pointer;
}

#interests-table-body-see-details {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

#interests-table-body-see-details > svg {
  color: var(--primary);
  width: 24px;
  height: 16px;
}

#interests-table-body-see-details > span {
  font-weight: 700;
  color: var(--primary);
}

.arrow-table-icon {
  width: 24px;
  height: 16px;
  cursor: pointer;
}

.table-thead-flex {
  display: flex;
  align-items: center;
  gap: 12px;
}

.box-loading {
  display: grid;
  place-items: center;
  margin-top: 40px;
}

@media only screen and (max-width: 1140px) {
  .interests-table-component-filters-auto-complete {
    min-width: auto;
  }
}
</style>
