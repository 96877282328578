import moment from "moment";
import { parseISO } from "date-fns";

export const formatDate = (value) => {
  if (value == "0001-01-01T00:00:00") return "-";
  return moment(value).format("DD/MM/YYYY ");
};

// Preciso converter um new date para o timezone correto
export const convertUTCDateToLocalDate = (date) => {
  let newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return newDate;
};

export const parseStringDate = (dateString) => {
  let parsedDate = dateString.replace(/-/g, "/");
  const ISODate = new Date(removeTimeFromDate(parsedDate)).toISOString();
  return parseISO(ISODate);
};

const removeTimeFromDate = (dateString) => {
  return dateString.split("T")[0];
};
