export const roundStatusEnums = {
  AWAITING_PUBLISH: 1,
  PUBLISHED: 2,
  CLOSED: 3,
  CANCELED: 4,
};

export const roundTabEnum = {
  ROUND_DATA: 0,
  COMMITMENT: 1,
  CLOSING: 2,
  BRIEFING_AND_PERMISSIONS: 3,
};

export const roundTypeOpportunity = {
  AvailableOpportunity: 1,
  ExclusiveOpportunity: 2,
};

export const roundTableEnum = {
  ROUND: 1,
  COMPANY: 2,
  STARTFUNDING: 3,
  ENDFUNDING: 4,
  STATUS: 5,
  CAPTURE: 6,
};

export const roundAccessesTableEnum = {
  INVESTOR: 1,
  VIEWED_LAST: 2,
};
